.navbar-header .navbar {
    white-space: nowrap;
    font-size: 1.25rem;
    padding: 18px;
    position: fixed;
    z-index: 1000;
    top: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    display: flex;
    display: -webkit-box;
    -webkit-transition: background-color 0.5s linear, padding 0.2s ease-in, -webkit-transform 0.4s ease-in-out;
    transition: background-color 0.5s linear, padding 0.2s ease-in, -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, background-color 0.5s linear, padding 0.2s ease-in;
    transition: transform 0.4s ease-in-out, background-color 0.5s linear, padding 0.2s ease-in, -webkit-transform 0.4s ease-in-out;
    box-shadow: 0 2px 4px rgb(81 107 152 / 16%);
    width: 100%;
    background: #fff;
}

@media (max-width:700px) {
    .righ {
        text-align: start;
    }
}

.wauto {
    margin-left: 40px;
    margin-right: 160px;
    width: 220px;
    height: 30px;
    max-height: 100%;
    vertical-align: middle;
    border-style: none;
}

.right {
    margin-left: 380px;
}

.righ {
    color: black;
    font-size: 15px;
}

.righ:hover {
    color: orange;
}

.lool > button:hover {
    background-color: orange;
    border: none;
}

.lists > li {
    align-items: flex-start;
}