.community_title {
  border-top: 1px solid lightgray;
  /* border-bottom: 1px solid lightgray; */
  height: 1.5em;
}
.suplex > button:hover {
  background-color: orange;
  border: none;
}
.AFTER {
  margin-top: 120px;
}
.ccsss {
  background-color: rgb(197, 195, 192);
}
.question_user {
  display: inline-block;
  width: 10%;
  margin: auto;
  align-items: center;
}