.header_row {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 120px 20px 30px;
  margin-top: 12px;
}
.blue_button {
  background-color: #516cf0;
  color: #fff;
  border-radius: 6px;
  padding: 0px 70px;
}
.blue_button:hover {
  background-color: orange;
}
.header_border {
  color: black;
  font-weight: bold;
}
.search {
  display: flex;
  justify-content: space-between;
  border: none;
}
.search_bar {
  width: 15%;
  border: 0.5px solid;
  border-radius: 5px;
  padding-left: 15px;
}