pre.s-code-block {
  font-size: var(--fs-body1);
  font-family: var(--ff-mono);
  line-height: var(--lh-md);
  color: var(--highlight-color);
  background-color: var(--highlight-bg);
  border-radius: var(--br-md);
  margin: 0;
  padding: var(--su12);
  overflow: auto;
  scrollbar-color: var(--scrollbar) transparent;
  background-color: #F6F6F6;
  max-height: 300px;
  color: rgba(51, 50, 126, 0.925);
  border-radius: 20px;
}

.s-prose pre {
  overflow-wrap: normal;
}

.hi {
  width: 100%;
  height: 100%;
}

