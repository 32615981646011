.login {
    padding-top: 150px;
    background-image: url('../../Resources/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.aa {
    color: #61355e;
}

@media (min-width: 800px) {
    .login {
        display: flex;
    }
}

.pass {
    margin-right: -30px;
    margin-top: -12px;
}

.left .sup {
    height: 300px;
    margin: 40px 0 25px 50px;
    padding: 20px 30px 350px 40px;
    position: relative;
    top: 5;
    left: 0;
    overflow: hidden;
    box-shadow: 0 3px 5px rgb(0 0 0 / 5%);
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 12px rgb(158 158 158 / 12%);
    border-radius: 8px;
}
/* 
.form > button {
    padding: 5px 30px;
} */

.formbtn {
    background-color: orange;
    border: none;
}

.stt{
    text-decoration: none;
}

.stt:hover {
    text-decoration: underline;
}

.about h5 {
    padding-top: 20px;
    color: rgb(243, 135, 11);
}

.left .form-control {
    color: #333333;
    font-size: 16px;
    height: 50px;
    padding: 12px 5px 12px ;
    outline: none;
    border: 0 none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 110px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.st {
    margin-left: 250px;
    text-decoration: none;
}

.st:hover {
    text-decoration: underline;
}

/* .right {
    margin-left: 350px;
} */

/* .about > button {
    background-color: orange;
} */

/* .about > button:hover {
    background-color: orange;
} */

